<template>
  <div class="login-layout">
    <div class="logo">
      <van-image
        class="logoimg"
        width="30vw"
        height="30vw"
        round
        fit="contain"
        lazy-load
        :src="require('@/assets/images/logo.png')"
      />
    </div>
    <van-form class="login-form" @submit="onSubmit">
      <van-cell-group>
        <van-field
          v-model="invitecode"
          :placeholder="PAGELANG.invitecode"
          left-icon="aog iconfont icon-yaoqingma-02"
		  :rules="rules.invitecode"
        />
		
		<van-field
		  v-model="curPerfix"
		  is-link
		  readonly
		  left-icon="aog iconfont icon-ditu"
		  @click="showPrefix = true"
		/>
		<van-popup v-model:show="showPrefix" round position="bottom">
		  <van-picker
		    :columns="mobileperfix"
		    v-model="selectedlang"
		    @cancel="showPrefix = false"
		    @confirm="onConfirm"
		    :title="PAGELANG.请选择地区"
			:columns-field-names="{ text: 'countryName' ,value:'countryNum' }"
			:rules="rules.username"
			defaultIndex=2
		  />
		</van-popup>
        <van-field
          v-model="username"
          :placeholder="PAGELANG.username"
          left-icon="aog iconfont icon-mobile"
          :rules="rules.username"
        />
		<van-field
		   v-model="captcha"
		   center
		   placeholder="请输入短信验证码"
		   left-icon="aog iconfont icon-mobile"
		   :rules="rules.captcha"
		>
		<template #right-icon>
		       <van-button  size="small"  type="primary" @click="sms()"  :text="codeTime"></van-button>
		</template>
		</van-field>
      </van-cell-group>

      <van-button
        v-if="isloading"
        color="var(--van-card-price-color)"
        block
        loading
        type="primary"
        :loading-text="PAGELANG.in_login"
        size="large"
        class="btn-login"
      />
      <van-button
        v-else
        color="var(--van-card-price-color)"
        block
        type="primary"
        native-type="submit"
        size="large"
        class="btn-login"
      >
        {{ PAGELANG.login_btn }}
      </van-button>
    </van-form>
  </div>


</template>
<style>
.van-icon-aog {
  color: #cfaf6d;
}
</style>
<style scoped>
@media (max-width: 768px) {
  .login-layout-pc {
    display: none;
  }
  .login-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  .login-layout .logo {
    position: relative;
    border-radius: 50%;
  }

  .login-layout .logo::before,
  .login-layout .logo::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
  }

  .login-layout .logo::before {
    top: 1px;
    left: 1px;
    z-index: 10;
    background-color: #fff;
  }

  .login-layout .logo::after {
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1px;
    background: linear-gradient(to right, #fff, #d5ba80);
  }

  .login-layout .logo .logoimg {
    z-index: 100;
  }

  .login-form {
    width: 90vw;
    margin-top: 9vw;
    --van-cell-horizontal-padding: 0;
    --van-padding-md: 0;
  }

  .van-icon-aog {
    color: #cfaf6d;
  }

  .forget {
    line-height: 38px;
    font-size: 12px;
    text-align: right;
  }

  .forget > a {
    color: #143949;
  }

  .btn-login {
    margin-top: 40px;
  }

  .register {
    text-align: center;
    padding-top: 20px;
    line-height: 44px;
    font-size: 12px;
    color: #979797;
  }

  .register > a {
    font-size: 14px;
    color: #143949;
    margin-left: 10px;
  }
}
</style>
<style src="../assets/css/login.css" scoped></style>

<script>
import { ref, getCurrentInstance } from "vue";
import { closeToast, showNotify, showLoadingToast,showToast } from "vant";
import { getCookie, setCookie } from "../util/index.js";


export default {

  data() {
	  return {
		  time:60,
		  curPerfix: "中國台灣",
		  areaPhone:"(886)",
	  }
  },
  
  created() {
    this.axios.get(this.actions.area_prefix).then((response) => {
      console.log(response, this.langcode);
      this.mobileperfix = response.data.data;
      let langitem = this.mobileperfix.find((item) => {
        if (item.value == this.langcode) return true;
        return false;
      });
  
      if (langitem) {
        this.selectedlang = [langitem.value];
        this.curPerfix = langitem.text;
      }
  
      // if(langitem)
      //   this.selectedlang =
      // // this.curPerfix = response.data[0].text;
    });
  },
  
  methods: {
 //    onConfirm({ selectedOptions }) {
 //      this.showPrefix = false;
 //      this.curPerfix = selectedOptions[0].text;
 //      setCookie("lh_aog_langcode", selectedOptions[0].value);
 //      // this.langcode = selectedOptions[0].value;
 //      //getCurrentInstance().config.globalProperties.langcode = selectedOptions[0].value;

 //      //this.appContext.config.globalProperties.langcode = selectedOptions[0].value;

 //      location.reload();
 //    },
	
	onConfirm({ selectedOptions }) {
	  console.log('selectedOptions',selectedOptions);
	  this.showPrefix = false;
	  this.curPerfix = selectedOptions[0].countryName;
	  this.areaPhone=selectedOptions[0].countryNum;
	  //setCookie("lh_aog_langcode", selectedOptions[0].value);
	  //location.reload();
	},
	
	//發送短信驗證碼獲取token
	sms(){
		if(this.time!=60){
		  return;	
		}
		this.set_interval=setInterval(()=>{
			this.fun();
		},1000)
		this.axios.get(this.actions.getSmsToken,{params:{
          // cateid: this.cateid * 1,
		  phone:this.areaPhone+this.username,
          platformType:1,
        }}).then((response)=>{
			let { code, message } = response.data;
			if (code == "200") { 
				console.log(response)
			    this.getSms(response.data.data.token)
			}
			else{
				showToast({
				  message:message,
				  forbidClick: true,
				});
			}
		})
	},
	getSms(token){
		this.axios.get(this.actions.getSms,{params:{
		  // cateid: this.cateid * 1,
		  phone:this.areaPhone+this.username,
		  platformType:1,
		  token:token
		}}).then((response)=>{
			let { code, message } = response.data;
			if (code == "200") {
				showToast({
				  message:message,
				});
				// console.log(response)
				// this.disabledSms=true
			    // this.set_interval=setInterval(()=>{
				// 	this.fun()
				// },1000)

			}
			else{
				showToast({
				  message:message,
				  forbidClick: true,
				});
			}
		})
	},
	fun() {
		console.log('12312312')
		console.log(this.time)
		this.time=this.time-1;
		if (this.time >= 0) {
			this.codeTime =this.time+ "重新发送";
			this.$forceUpdate();
		} else if (this.time < 0) {
			
			this.codeTime = "重新发送";
			this.disabledSms = false; //倒计时结束能够重新点击发送的按钮 
			clearInterval(this.set_interval); //清除定时器 
			this.time = 60; //设置循环重新开始条件 
			this.$forceUpdate();
		}
		return true
	},

    onChangeLang(val) {
      this.langcode = val;
      setCookie("lh_aog_langcode", val);
      location.reload();
    },

    onRegister() {
      this.$refs.refLogin.validate((valid) => {
        if (valid) {
          this.invitecode = this.reginfo.invitecode;
          this.agentname = this.reginfo.agentrequired;
          this.password = this.reginfo.password;
          this.password2 = this.reginfo.password2;
          this.username = this.reginfo.username;

          this.onSubmit();
        } else {
          return false;
        }
      });
    },

    onSubmit() {
      console.log("submit");
      console.log(this.username, this.password, this.password2, this.agentname);

      this.isloading = true;

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      // if (this.password != this.password2) {
      //   // showFailToast(this.PAGELANG.inconformity);
      //   closeToast();
      //   this.isloading = false;
      //   showNotify({
      //     message: this.PAGELANG.inconformity,
      //     type: "warning",
      //   });
      //   return;
      // }
	  


      let postobj = new Object();

      postobj.inviteCode = this.invitecode;
	  postobj.halfCode="";
      postobj.phone = this.areaPhone+this.username;
      postobj.captcha=this.captcha;
	  postobj.deviceType=4;
	  postobj.type=2;
	  postobj.levelId="8b8dd6b674d44620bcea82ad41008b0b";	
	  postobj.inviteMemberId=""
	  // postobj.inviteMemberId="3516d52ca77542cb99434702652ad00c";
	  postobj.brandId="234690b3c10d447988954ec717d7acb8";
	  
	  if(this.invitecode.length>0){
	  		  this.axios.get(this.actions.memberCodeInvite,{params:{
				  inviteCode:this.invitecode
			  }}).then((response) => {
				  
				  if(response.data.code==200){
					   postobj.inviteMemberId=response.data.data.memberId
					   
					   this.axios.post(this.actions.bindCompany,postobj).then((response) => {
					     //注册返回的数据
					     console.log(response);
					   
					     this.isloading = false;
					   
					     let showinfo = this.PAGELANG[response.data.retinfo];
					   
					     if (!showinfo) {
					       showinfo = response.data.retinfo;
					     }
					   
					     closeToast();
					   
					     if (response.data.code == "200") {
					       //成功
					       setCookie("agentlogintoken", response.data.data.token,30);
					       this.$router.push('/registerFrom');
					   		  return;	
					       // showSuccessToast({message: showinfo, onClose: () => {
					       //   this.$router.push('/appflow');
					       // }});
					       // showNotify({
					       //   message: showinfo,
					       //   type: "success",
					       //   onClose: () => {
					       //     this.$router.push("/appflow");
					       //   },
					       // });
					     } else {
					       // showFailToast(showinfo);
					       showNotify({
					         message:response.data.message,
					         type: "warning",
					       });
						    this.isloading = false;
					     }
					   });
					   
				  }else{
					showNotify({
					  message:response.data.message,
					  type: "warning",
					});
				   this.isloading = false;	
				   closeToast();
				   
					  
				  }
				  
			  })
	  }else{
		  postobj.inviteMemberId="3516d52ca77542cb99434702652ad00c";
		  this.axios.post(this.actions.bindCompany,postobj).then((response) => {
		    //注册返回的数据
		    console.log(response);
		  
		    this.isloading = false;
		  
		    let showinfo = this.PAGELANG[response.data.retinfo];
		  
		    if (!showinfo) {
		      showinfo = response.data.retinfo;
		    }
		  
		    closeToast();
		  
		    if (response.data.code == "200") {
		      //成功
		      setCookie("agentlogintoken", response.data.data.token,30);
		      this.$router.push('/registerFrom');
		      return;	
		      // showSuccessToast({message: showinfo, onClose: () => {
		      //   this.$router.push('/appflow');
		      // }});
		      // showNotify({
		      //   message: showinfo,
		      //   type: "success",
		      //   onClose: () => {
		      //     this.$router.push("/appflow");
		      //   },
		      // });
		    } else {
		      // showFailToast(showinfo);
		      showNotify({
		        message:response.data.message,
		        type: "warning",
		      });
		    }
		  });
	  }

	  
      console.log(postobj);
	  
    },
  },
  mounted() {
    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });
    closeToast();
    // this.axios.get(this.actions.mobile_prefix).then((response) => {
    //   closeToast();
    //   this.mobileperfix = response.data;
    //   let langitem = this.mobileperfix.find((item) => {
    //     if (item.value == this.langcode) return true;
    //     return false;
    //   });

    //   if (langitem) {
    //     this.selectedlang = [langitem.value];
    //     this.curPerfix = langitem.text;
    //   }
    // });
  },
  setup() {
    //const $axios = inject("$axios");
    //const $refs = inject("$refs");

    const _this = getCurrentInstance();
    const gp = _this.appContext.config.globalProperties;

    const PAGELANG = gp.LANG.pages.register;
    const SYSLANG = gp.LANG.system;

    const langcode = getCookie("lh_aog_langcode");

    const appContext = _this.appContext;
    const mobileperfix = ref([]);
    const showPrefix = ref(false);
    const invitecode = ref("");
    const username = ref("");
    const password = ref("");
    const password2 = ref("");
    const agentname = ref("");
    const email = ref("");
    const isloading = ref(false);
	const captcha=ref("");
	const codeTime="獲取驗證碼"
	const disabledSms=ref(false);
    const rules = {
	  invitecode:[{required: true, message: "請輸入邀請碼"}],
      username: [{ required: true, message: PAGELANG.username }],
	  captcha:  [{ required: true, message: PAGELANG.yanzheng }],
      password: [{ required: true, message: PAGELANG.password }],
      password2: [{ required: true, message: PAGELANG.password2 }],
      agentrequired: [{ required: true, message: PAGELANG.agentrequired }],
    };
    const selectedlang = ref([""]);
    const reginfo = ref([""]);

    return {
      PAGELANG,
      SYSLANG,
      mobileperfix,
      invitecode,
      username,
	  captcha,
      password,
      password2,
      agentname,
      email,
      showPrefix,
      isloading,
      rules,
      selectedlang,
      appContext,
      langcode,
      reginfo,
	  codeTime,
	  disabledSms,
    };
  },
};
</script>